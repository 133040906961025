import React from "react"
import Link from "gatsby-link"
import { Container, Row, Col } from "reactstrap"

export default function Footer() {
  return (
    <footer>
      <div className="footer section">
        <Container>
          <Row>
            <Col md={4}>
              <h5>About Us</h5>
              <div class="info">
                <ul className="list-unstyled">
                  <li>
                    <Link to="/" rel="nofollow">Home</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">News</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Churches</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Who We Are</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <h5>Departments</h5>
              <div className="info d-flex justify-content-start">
                <ul className="list-unstyled mr-5">
                  <li>
                    <Link to="/" rel="nofollow">Evangelism</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Canvassing</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Publishing</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Multimedia</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">¥outh</Link>
                  </li>
                </ul>
                <ul className="list-unstyled ml-5">
                  <li>
                    <Link to="/" rel="nofollow">Ministerial</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Education</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Health</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Social & Family</Link>
                  </li>
                  <li>
                    <Link to="/" rel="nofollow">Good Samaritan</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <p>IMSSDARM Multimedia Department 2021</p>
        </Container>

      </div>
    </footer>
  )
}
