import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.css"

export default function Layout({children}){
  return(
    <div className="layout">
      <Navbar />


      {children}


      <Footer />

    </div>

  )
}
