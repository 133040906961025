import React, { useState } from 'react';
import Link from "gatsby-link";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from 'reactstrap'



const Navi = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="header-navigation">
      <div className="header-strip py-3">
        <Container>
          <Row className="d-flex justify-content-between">
              <Col sm={6} className="d-flex align-items-center">
                <StaticImage
                  src="../images/maps-and-flags.svg"
                  alt="Address"
                  width={22}
                  />
                <span className="text-white ml-2">
                  625 West Avenue, Cedartown, GA 30125 USA</span>
              </Col>
              <Col sm={6} className="d-flex align-items-center justify-content-end">
                <StaticImage
                  src="../images/email.svg"
                  alt="Email"
                  width={22}
                  />
                <a className="text-white ml-2 mr-4" href="mailto:AsianDivision@sda1844.org" alt="Email">
                                  <span>AsianDivision@sda1844.org</span>
                  </a>
                  <StaticImage
                    src="../images/phone-call.svg"
                    alt="Phone"
                    width={22}
                    />
                  <a className="text-white ml-2" href="tel:639088915581" alt="Email">
                  <span>+63 9088915581</span>
                </a>
              </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <Navbar expand="md p-0">
              <NavbarBrand href="/">
                <StaticImage
                  src="../images/ims-asia-pacific-logo.png"
                  alt="IMSSDARM Logo"
                  width={510}
                />
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-0 d-flex justify-content-end" navbar>
                  <NavItem>
                    <Link to="/">Home</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/news">News</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/our-churches">Our Churches</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/who-we-are">Who We Are</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/contact-us">Contact Us</Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Navi;
